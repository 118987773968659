<template>
  <footer class="app-footer text-center py-4">
    <small class="text-muted">
      Copyright © 2024 Aibase. All rights reserved.
    </small>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>

<style scoped>
.app-footer {
  background-color: #f8f9fa;
  font-size: 0.875rem;
}
</style>
