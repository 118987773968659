<template>
  <main class="app-body container py-5">
    <!-- 大标题部分 -->
    <div class="text-center">
      <h1 class="display-5 fw-bold">提取短视频文案只需要几秒钟</h1>
      <p class="lead">
        提供 <span class="text-primary fw-bold">短视频链接</span> 就够了
      </p>
      <small class="text-muted">支持多个平台</small>
    </div>

    <!-- 表单部分 -->
    <div class="form-section my-5">
      <div class="input-group mb-3">
        <input type="text" class="form-control" v-model="videoUrl" placeholder="请输入短视频的分享链接">
        <button :disabled="isButtonDisabled" class="btn btn-danger" @click="extractTask">
          {{ buttonText }}
        </button>
      </div>
    </div>

    <!-- 原文案 和 改写文案 -->
    <div class="row">
      <!-- 原文案 -->
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <span class="badge bg-danger me-2">2</span> 提取到的文案
          </div>
          <div class="card-body">
            <textarea ref="extractedTextarea" class="form-control" rows="10" v-model="extractedText" placeholder="文案内容"></textarea>
            <button :disabled="isRewriteButtonDisabled" class="btn btn-danger mt-3" @click="rewriteText">
              {{ rewriteButtonText }}
            </button>
            <button class="btn btn-success mt-3 ms-2" @click="copyExtractedText">复制文案内容</button>
          </div>
        </div>
      </div>

      <!-- 改写文案 -->
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <span class="badge bg-secondary me-2">3</span> 改写后的文案
          </div>
          <div class="card-body">
            <textarea ref="rewrittenTextarea" class="form-control" rows="10" v-model="rewrittenText" placeholder="改写内容"></textarea>
            <button class="btn btn-success mt-3" @click="copyRewrittenText">复制文案内容</button>
          </div>
        </div>
      </div>
    </div>

    <!-- 引入 UpgradeModal 组件 -->
    <UpgradeModal :show="showUpgradeModal" @close="closeUpgradeModal" />

    <!-- 公共模态弹窗 -->
    <div v-if="showModal" class="modal fade show" style="display: block;" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">提示</h5>
            <button type="button" class="btn-close" @click="hideModal"></button>
          </div>
          <div class="modal-body">
            {{ modalMessage }}
          </div>
        </div>
      </div>
    </div>

    <!-- 模态弹窗的背景遮罩 -->
    <div v-if="showModal" class="modal-backdrop fade show"></div>
  </main>
</template>

<script>
import UpgradeModal from './UpgradeModal.vue';  // 引入升级模态框组件

export default {
  name: 'AppBody',
  components: {
    UpgradeModal
  },
  data() {
    return {
      videoUrl: '',
      extractedText: '',  // 提取的文案内容
      rewrittenText: '',  // 改写后的文案内容
      buttonText: '提取文案',
      rewriteButtonText: '一键改写',
      taskId: null,  // 任务 ID
      isButtonDisabled: false,  // 提取文案按钮是否禁用
      isRewriteButtonDisabled: false,  // 改写文案按钮是否禁用
      showUpgradeModal: false,  // 控制开通会员模态框显示
      showAlert: false,  // 控制提示框显示
      showModal: false,  // 控制公共模态弹窗显示
      modalMessage: ''  // 模态弹窗显示的消息
    };
  },
  methods: {
    extractTask() {
      // 检查用户是否登录
      const userId = localStorage.getItem('user_id');
      const token = localStorage.getItem('token');

      if (!userId || !token) {
        // 用户未登录，发出事件显示登录窗口
        this.$emit('showLoginModal');
        return;
      }

      if (this.videoUrl === '') {
        this.showMessage('请输入短视频链接');
        return;
      }

      // 禁用按钮以防止重复点击
      this.isButtonDisabled = true;
      this.buttonText = '提取中...';

      // 创建 FormData 对象
      const formData = new FormData();
      formData.append('user_id', userId);
      formData.append('audio_url', this.videoUrl);

      // 发起任务请求，获取 task_id
      fetch('https://res.2415.cn/api/User/task', {
        method: 'POST',
        body: formData // 使用 FormData 作为请求体
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === 1000) {
            // 显示开通会员提示
            this.showUpgradeModal = true;
            this.isButtonDisabled = false;  // 恢复按钮
            this.buttonText = '提取文案';
          } else if (data.task_id) {
            this.taskId = data.task_id;
            this.pollForResult();  // 开始轮询查询任务结果
          } else {
            this.showMessage('任务创建失败');
            this.isButtonDisabled = false;  // 恢复按钮
            this.buttonText = '提取文案';
          }
        })
        .catch(error => {
          console.error('请求失败', error);
          this.isButtonDisabled = false;  // 恢复按钮
          this.buttonText = '提取文案';
        });
    },
    pollForResult() {
      // 轮询查询任务结果
      this.pollInterval = setInterval(() => {
        fetch(`https://apis.2415.cn/query/${this.taskId}`, {
          method: 'GET'
        })
        .then(response => response.json())
        .then(data => {
          if (data.status === 'processing') {
            // 更新按钮进度
            this.buttonText = `提取进度 ${data.progress}%`;
          } else if (data.status === 'success') {
            // 提取成功，更新文案内容
            this.extractedText = data.text;
            this.buttonText = '提取成功';
            this.adjustTextareaHeight();  // 调整 textarea 高度
            clearInterval(this.pollInterval);  // 停止轮询
            this.isButtonDisabled = false;  // 恢复按钮
          }
        })
        .catch(error => {
          console.error('轮询请求失败', error);
          clearInterval(this.pollInterval);  // 停止轮询
          this.buttonText = '提取失败，请重试';
          this.isButtonDisabled = false;  // 恢复按钮
        });
      }, 3000);  // 每 3 秒查询一次
    },
    closeUpgradeModal() {
      this.showUpgradeModal = false; // 关闭开通会员模态框
    },
    hideAlert() {
      this.showAlert = false;  // 隐藏提示框
    },
    adjustTextareaHeight() {
      // 调整 textarea 高度
      this.$refs.extractedTextarea.style.height = '500px';
      this.$refs.rewrittenTextarea.style.height = '500px';
    },
    rewriteText() {
      // 检查用户是否登录
      const userId = localStorage.getItem('user_id');
      const token = localStorage.getItem('token');

      if (!userId || !token) {
        // 用户未登录，发出事件显示登录窗口
        this.$emit('showLoginModal');
        return;
      }

      if (this.extractedText === '') {
        this.showMessage('请先提取文案');
        return;
      }

      // 禁用按钮以防止重复点击
      this.isRewriteButtonDisabled = true;
      this.rewriteButtonText = '改写中...';

      // 创建 FormData 对象
      const formData = new FormData();
      formData.append('user_id', userId);
      formData.append('centent', this.extractedText);

      // 发起改写请求
      fetch('https://res.2415.cn/api/User/ai', {
        method: 'POST',
        body: formData // 使用 FormData 作为请求体
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === 200) {
            // 改写成功，更新改写后的文案内容
            this.rewrittenText = data.content;
            this.rewriteButtonText = '改写成功';
          } else {
            this.showMessage('改写失败');
            this.rewriteButtonText = '一键改写';
          }
          this.isRewriteButtonDisabled = false;  // 恢复按钮
        })
        .catch(error => {
          console.error('改写请求失败', error);
          this.rewriteButtonText = '一键改写';
          this.isRewriteButtonDisabled = false;  // 恢复按钮
        });
    },
    copyExtractedText() {
      // 复制提取的文案内容
      navigator.clipboard.writeText(this.extractedText).then(() => {
        this.showMessage('文案内容已复制到剪贴板');
      }).catch(err => {
        console.error('复制失败: ', err);
      });
    },
    copyRewrittenText() {
      // 复制改写后的文案内容
      navigator.clipboard.writeText(this.rewrittenText).then(() => {
        this.showMessage('改写后的文案内容已复制到剪贴板');
      }).catch(err => {
        console.error('复制失败: ', err);
      });
    },
    showMessage(message) {
      this.modalMessage = message;
      this.showModal = true;
      setTimeout(() => {
        this.hideModal();
      }, 3000);
    },
    hideModal() {
      this.showModal = false;
    }
  },
  beforeUnmount() {
    // 清理轮询定时器
    if (this.pollInterval) {
      clearInterval(this.pollInterval);
    }
  }
};
</script>

<style scoped>
.app-body {
  max-width: 900px;
}

.text-center h1 {
  color: #333;
}

.form-control {
  height: 45px;
}

.btn-danger {
  background-color: #ff5678;
  border-color: #ff5678;
}

.btn-success {
  background-color: #8bc34a;
  border-color: #8bc34a;
}

.custom-alert {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  padding: 12px 16px;
}

.custom-alert .bi-exclamation-triangle-fill {
  color: #ff5678;
  font-size: 18px;
}
</style>