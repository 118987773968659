<template>
    <div v-if="showLoginModal" class="login-modal-overlay">
      <div class="login-modal-content">
        <div class="modal-header text-center">
          <h5 class="modal-title">手机验证码登录/注册</h5>
        </div>
        <div class="modal-body">
          <!-- 手机号输入 -->
          <div class="input-group mb-3">
            <span class="input-group-text">+86</span>
            <input type="text" class="form-control" placeholder="请输入手机号" v-model="mobile" maxlength="11">
          </div>
  
          <!-- 验证码输入 -->
          <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="请输入验证码" v-model="verifyCode">
            <button :disabled="countdown > 0" @click="sendSms" class="btn btn-primary">
              {{ countdown > 0 ? `${countdown}秒后重发` : '获取验证码' }}
            </button>
          </div>
  
          <!-- 登录按钮 -->
          <div class="text-center">
            <button class="btn btn-primary btn-block w-100" @click="login">登 录</button>
          </div>
        </div>
  
        <!-- 隐私政策和服务协议 -->
        <div class="modal-footer text-center">
          <small class="text-muted">
            登录即代表同意 <a href="#">《隐私政策》</a> 和 <a href="#">《服务协议》</a>
          </small>
        </div>
  
        <!-- 关闭按钮 -->
        <button class="close-btn" @click="closeLoginModal">×</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        mobile: '',
        verifyCode: '',
        countdown: 0,
        verifyId: '',
        showLoginModal: false
      };
    },
    methods: {
      openLoginModal() {
        this.showLoginModal = true;
      },
      closeLoginModal() {
        this.showLoginModal = false;
      },
      sendSms() {
        if (this.mobile.length === 11) {
          fetch('https://res.2415.cn/api/User/sendSms', {
            method: 'POST',
            body: new URLSearchParams({ mobile: this.mobile })
          })
          .then(response => response.json())
          .then(data => {
            if (data.status === 200) {
              this.verifyId = data.key;
              this.startCountdown();
            } else {
              alert(data.msg);
            }
          });
        } else {
          alert('请输入有效的手机号');
        }
      },
      startCountdown() {
        this.countdown = 60;
        let interval = setInterval(() => {
          this.countdown--;
          if (this.countdown === 0) {
            clearInterval(interval);
          }
        }, 1000);
      },
      login() {
        if (this.mobile && this.verifyCode) {
          fetch('https://res.2415.cn/api/User/login', {
            method: 'POST',
            body: new URLSearchParams({
              mobile: this.mobile,
              verify_id: this.verifyId,
              verify: this.verifyCode
            })
          })
          .then(response => response.json())
          .then(data => {
            if (data.status === 200) {
              // 保存 user_id 和 token 到 localStorage
              localStorage.setItem('user_id', data.data.user_id);
              localStorage.setItem('token', data.token);
              this.$emit('loginSuccess'); // 登录成功事件
  
              // 登录成功后自动刷新页面
              window.location.reload();
            } else {
              alert('登录失败，请重试');
            }
          });
        } else {
          alert('请输入手机号和验证码');
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .login-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* 确保模态框处于最顶层 */
  }
  
  .login-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 350px;
    position: relative;
  }
  
  .modal-header {
    margin-bottom: 20px;
  }
  
  .modal-title {
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .close-btn {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    
    border: none;
    cursor: pointer;
  }
  </style>
  