<template>
  <div>
    <AppHeader @openLoginModal="openLoginModal" />
    <AppBody @showLoginModal="openLoginModal" />
    <AppFooter />
    <LoginModal ref="loginModal" @loginSuccess="handleLoginSuccess" />
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue';
import AppBody from './components/AppBody.vue';
import AppFooter from './components/AppFooter.vue';
import LoginModal from './components/LoginModal.vue';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppBody,
    AppFooter,
    LoginModal
  },
  methods: {
    openLoginModal() {
      this.$refs.loginModal.openLoginModal();  // 打开登录模态框
    },
    handleLoginSuccess() {
      console.log('用户已登录');
      // 登录成功后的逻辑
    }
  }
};
</script>
