<template>
  <header class="app-header py-3">
    <div class="container d-flex justify-content-between align-items-center">
      <!-- Logo -->
      <div class="d-flex align-items-center">
        <img src="/img/logo.png" alt="logo" class="logo me-3">
        <!-- 导航栏 -->
        <nav class="navbar navbar-expand-lg navbar-light">
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
              <li class="nav-item">
                <a class="nav-link" href="#">首页</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">提取教程</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="https://down.cqrfd.cn" target="_blank">短视频下载</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#" @click="openUpgradeModal">开通会员</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#" @click="openCustomerServiceModal">联系客服</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <!-- 登录按钮或手机号显示 -->
      <div v-if="!isLoggedIn">
        <button @click="openLoginModal" class="btn btn-light d-flex align-items-center">
          登录
        </button>
      </div>
      <div v-else class="user-info-container">
        <span @mouseover="showUserInfo = true" @mouseleave="showUserInfo = false" class="btn btn-light">
          {{ user.mobile }}
          <div v-if="showUserInfo" class="user-info-popover">
            <p>用户名: {{ user.username }}</p>
            <p>手机号: {{ user.mobile }}</p>
            <p>套餐: {{ vipPlan }}</p>
            <p v-if="user.Ktime && !isKtimeExpired">到期时间: {{ user.Ktime }}</p>
            <button v-else class="btn btn-primary" @click="openUpgradeModal">{{ user.Ktime ? '续费会员' : '开通会员' }}</button>
            <button class="btn btn-danger mt-2" @click="logout">退出</button>
          </div>
        </span>
      </div>
    </div>

    <!-- 客服模态窗口 -->
    <div class="modal fade" id="customerServiceModal" tabindex="-1" aria-labelledby="customerServiceModalLabel" aria-hidden="true" ref="customerServiceModal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="customerServiceModalLabel">联系客服</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center">
            <!-- 二维码图片 -->
            <img src="/img/qrcode.jpg" alt="二维码" class="img-fluid">
            <!-- 微信号及复制按钮 -->
            <div class="mt-3">
              <p>微信号: <span id="wechatId">615744</span></p>
              <button @click="copyWeChatId" class="btn btn-primary">复制微信号</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 引入 UpgradeModal 组件 -->
    <UpgradeModal :show="showUpgradeModal" @close="closeUpgradeModal" />
  </header>
</template>

<script>
// 引入 Bootstrap JS
import { Modal } from 'bootstrap';

import UpgradeModal from './UpgradeModal.vue';

export default {
  components: {
    UpgradeModal
  },
  data() {
    return {
      isLoggedIn: false,
      showUserInfo: false,
      showUpgradeModal: false,
      user: {
        username: '',
        mobile: '',
        vip: 0,
        Ktime: ''
      }
    };
  },
  computed: {
    vipPlan() {
      switch (this.user.vip) {
        case 30: return '包月会员';
        case 90: return '季度会员';
        case 365: return '包年会员';
        default: return '未开通会员';
      }
    },
    isKtimeExpired() {
      if (!this.user.Ktime) return false;
      const currentTime = new Date();
      const ktimeDate = new Date(this.user.Ktime);
      return ktimeDate < currentTime;
    }
  },
  mounted() {
    this.checkLoginStatus();
  },
  methods: {
    openLoginModal() {
      this.$emit('openLoginModal');
    },
    checkLoginStatus() {
      const userId = localStorage.getItem('user_id');
      const token = localStorage.getItem('token');

      if (userId && token) {
        fetch('https://res.2415.cn/api/User/detail', {
          method: 'POST',
          headers: {
            'Authorization': token
          },
          body: new URLSearchParams({ user_id: userId })
        })
        .then(response => response.json())
        .then(data => {
          if (data.status === 200) {
            this.user = data.data;
            this.isLoggedIn = true;
          } else {
            this.isLoggedIn = false;
            localStorage.removeItem('user_id');
            localStorage.removeItem('token');
          }
        });
      } else {
        this.isLoggedIn = false;
      }
    },
    logout() {
      localStorage.removeItem('user_id');
      localStorage.removeItem('token');
      window.location.reload();
    },
    openUpgradeModal() {
      this.showUpgradeModal = true;
    },
    closeUpgradeModal() {
      this.showUpgradeModal = false;
    },
    openCustomerServiceModal() {
      const modalElement = this.$refs.customerServiceModal;
      const myModal = new Modal(modalElement);
      myModal.show();
    },
    copyWeChatId() {
      const weChatId = document.getElementById('wechatId').innerText;
      navigator.clipboard.writeText(weChatId).then(() => {
        alert('微信号已复制: ' + weChatId);
      });
    }
  }
};
</script>

<style scoped>
.app-header {
  background-color: #698dc1;
}

.logo {
  width: 30px;
}

.btn-light {
  background-color: #fff0f5;
  color: #ff5678;
  font-weight: bold;
}

.user-info-container {
  position: relative;
}

.user-info-popover {
  position: absolute;
  background-color: white;
  padding: 10px;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  z-index: 100;
}

.btn-danger {
  background-color: #ff5678;
  border-color: #ff5678;
  color: white;
}

.navbar-nav {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin-right: 15px;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.nav-link:hover {
  text-decoration: underline;
}

.modal-content {
  padding: 20px;
}

.modal-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
}

.modal-title {
  color: #333;
  font-size: 18px;
  font-weight: bold;
}

.modal-body img {
  max-width: 200px;
  margin: 0 auto;
  display: block;
}

.modal-body p {
  margin-top: 15px;
  font-size: 16px;
  color: #333;
  font-weight: bold;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  padding: 10px 20px;
  font-size: 16px;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #004085;
}

#wechatId {
  color: #ff5678;
  font-weight: bold;
  font-size: 18px;
}
</style>
