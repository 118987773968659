import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
// 引入 Font Awesome 的 CSS
import '@fortawesome/fontawesome-free/css/all.css';

// 引入 Popper.js（如果需要单独使用）
import 'popper.js';

createApp(App).mount('#app')
