<template>
  <div v-if="show" class="modal-overlay">
    <div class="modal-content">
      <div class="modal-header d-flex justify-content-between align-items-center">
        <h5 class="modal-title">开通会员，畅享无限次数提取下载</h5>
        <button @click="closeModal" class="btn-close">×</button>
      </div>

      <!-- 显示套餐 -->
      <div class="plan-container">
        <div class="plan-card" 
             v-for="plan in plans" 
             :key="plan.plan_id" 
             :class="{ selected: selectedPlan === plan.plan_id }"
             @click="selectPlan(plan.plan_id, plan.price)">
          <div class="plan-info">
            <div class="plan-name">{{ plan.Quanyi }}</div>
            <div class="plan-price">￥{{ plan.price }}</div>
          </div>
        </div>
      </div>

      <!-- 支付方式选择 -->
      <div class="payment-method d-flex justify-content-center">
        <label class="pay-option">
          <i class="fab fa-weixin"></i> <!-- 微信图标 -->
          <input type="radio" value="1" v-model="paymentMethod" />
          <span class="pay-label">微信支付</span>
        </label>
        <label class="pay-option">
          <i class="fab fa-alipay"></i> <!-- 支付宝图标 -->
          <input type="radio" value="0" v-model="paymentMethod" />
          <span class="pay-label">支付宝支付</span>
        </label>
      </div>

      <!-- 立即开通按钮 -->
      <button @click="initiatePayment" class="btn btn-primary btn-pay">
        ￥{{ selectedPrice }} 立即开通
      </button>

      <!-- 二维码支付展示 -->
      <div v-if="qrcodeUrl" class="qrcode-container">
        <h5 class="qrcode-title">
          {{ paymentMethod === 1 ? '请使用微信扫码完成支付' : '请使用支付宝扫码完成支付' }}
        </h5>
        <img :src="qrcodeUrl" alt="支付二维码" class="qrcode-img" />
        <p>扫描上方二维码完成支付</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      plans: [],
      selectedPlan: null,
      selectedPrice: 0,
      paymentMethod: 1,  // 默认微信支付
      qrcodeUrl: '',  // 支付二维码URL
      orderId: null, // 存储订单ID以进行轮询
      paymentPolling: null, // 存储轮询的间隔ID
    };
  },
  mounted() {
    this.fetchPlans();
  },
  methods: {
    closeModal() {
      clearInterval(this.paymentPolling); // 确保在关闭时停止轮询
      this.$emit('close');  // 关闭模态框事件
    },
    fetchPlans() {
      fetch('https://res.2415.cn/api/Plan/index')
        .then(response => response.json())
        .then(data => {
          if (data.status === 200) {
            this.plans = data.data.data;
          }
        })
        .catch(error => {
          console.error('获取套餐信息失败', error);
        });
    },
    selectPlan(planId, price) {
      this.selectedPlan = planId;
      this.selectedPrice = price;
    },
    initiatePayment() {
      if (!this.selectedPlan) {
        alert('请选择一个套餐');
        return;
      }

      const userId = localStorage.getItem('user_id');
      const formData = new FormData();
      formData.append('userid', userId);
      formData.append('TotalAmount', this.selectedPrice);
      formData.append('PaymentMethod', this.paymentMethod);
      formData.append('logid_url', window.location.href);  // 新增 logid_url 参数

      // 判断设备类型
      let device;
      const userAgent = navigator.userAgent;
      if (/mobile/i.test(userAgent)) {
        device = 2; // 手机
      } else if (/tablet/i.test(userAgent)) {
        device = 3; // 平板
      } else {
        device = 1; // PC
      }
      formData.append('device', device);

      fetch('https://res.2415.cn/api/Order/add', {
        method: 'POST',
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === 200) {
            if (data.data.qrcode) {
              this.qrcodeUrl = data.data.qrcode;  // 显示支付二维码
              this.orderId = data.data.order_id;  // 获取订单ID
              this.startPolling();  // 开始轮询支付状态
            } else if (data.data.pay_url) {
              window.location.href = data.data.pay_url;  // 直接跳转到pay_url
            }
          } else {
            alert('发起支付失败');
          }
        })
        .catch(error => {
          console.error('支付请求失败', error);
        });
    },

    startPolling() {
      this.paymentPolling = setInterval(() => {
        this.checkOrderStatus();
      }, 5000); // 每5秒检查一次
    },
    checkOrderStatus() {
      const formData = new FormData();
      formData.append('order_id', this.orderId);

      fetch('https://res.2415.cn/api/Order/detail/', {
        method: 'POST',
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === 200) {
            if (data.data.OrderStatus === 1) {
              alert('支付成功');
              clearInterval(this.paymentPolling); // 停止轮询
              this.closeModal(); // 关闭模态窗口
              location.reload(); // 刷新页面
            }
          }
        })
        .catch(error => {
          console.error('检查订单状态失败', error);
        });
    }
  }
};
</script>

<style scoped>
/* 覆盖页面 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 600px;
  max-width: 90%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.btn-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

/* 套餐卡片 */
.plan-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.plan-card {
  flex: 1;
  margin: 0 10px;
  background: #f5f5f5;
  padding: 15px;
  text-align: center;
  border-radius: 8px;
  transition: background 0.3s, transform 0.2s;
  cursor: pointer;
  border: 2px solid transparent;
}

.plan-card.selected {
  border-color: #ff5722;
  background-color: #fff5f0;
  transform: scale(1.05);
}

.plan-info {
  margin-bottom: 10px;
}

.plan-name {
  font-size: 1.2rem;
  font-weight: bold;
}

.plan-price {
  font-size: 1.5rem;
  color: #ff5722;
  font-weight: bold;
}

/* 支付方式选择 */
.payment-method {
  margin-bottom: 20px;
  justify-content: center;
}

.pay-option {
  margin: 0 20px;
  font-size: 1.2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.pay-option i {
  font-size: 1.5rem;
  margin-right: 10px;
  color: #00c300; /* 微信图标颜色 */
}

.pay-option i.fa-alipay {
  color: #1677ff; /* 支付宝图标颜色 */
}

.pay-label {
  font-weight: bold;
}

/* 开通按钮 */
.btn-pay {
  background-color: #ff5722;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 1.2rem;
}

.qrcode-container {
  text-align: center;
  margin-top: 20px;
}

.qrcode-img {
  width: 200px;
  height: 200px;
}
</style>
